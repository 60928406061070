import React from 'react';
import './css/style.css';
import Index from './jsx';
function App() {
  return (
    <div className="App">
      <Index />
    </div>
  );
}

export default App;
